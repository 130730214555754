import { Typography } from '@/components/01-new/atoms/00-ui/Typography';
import { IFeedbackSwiperQuoteProps } from './index.types';

const FeedbackSwiperQuote = ({ title, description, author }: IFeedbackSwiperQuoteProps) => {
  return (
    <div className="flex flex-col justify-center gap-6">
      <Typography
        variant="h4"
        component="h4"
        className="text-green-7 text-center xl-1:text-start font-mono italic text-[20px]"
      >
        {`"${title}"`}
      </Typography>
      <Typography
        variant="h5"
        component="p"
        className="text-white text-center xl-1:text-start text-[16px]"
      >
        {description}
      </Typography>
      <div className="flex justify-center xl-1:justify-start">
        <div className="flex flex-col gap-2 items-center xl-1:items-start">
          <Typography
            component="p"
            variant="subtitle2"
            className="font-semibold text-[16px] text-green-7"
          >
            {author.name}
          </Typography>
          <Typography
            variant="subtitle2"
            component="p"
            className="text-[14px] text-white"
          >
            {author.role}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default FeedbackSwiperQuote;
