export const Stars = () => (
  <svg
    height="45px"
    width="150px"
    viewBox="0 0 300 50"
    className="w-[100px] md-tablet:w-[150px] h-full"
  >
    <polygon
      style={{ fill: '#fff' }}
      points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 
      10.288,52.549 13.467,34.013 0,20.887 18.611,18.182"
      transform="translate(0,0)"
    />
    <polygon
      style={{ fill: '#fff' }}
      points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 
      10.288,52.549 13.467,34.013 0,20.887 18.611,18.182"
      transform="translate(60,0)"
    />
    <polygon
      style={{ fill: '#fff' }}
      points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 
      10.288,52.549 13.467,34.013 0,20.887 18.611,18.182"
      transform="translate(120,0)"
    />
    <polygon
      style={{ fill: '#fff' }}
      points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 
      10.288,52.549 13.467,34.013 0,20.887 18.611,18.182"
      transform="translate(180,0)"
    />
    <polygon
      style={{ fill: '#fff' }}
      points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 
      10.288,52.549 13.467,34.013 0,20.887 18.611,18.182"
      transform="translate(240,0)"
    />
  </svg>
);
