export const FeedbackSwiperComposition = () => {
  return (
    <>
      <svg
        width="800"
        height="1000"
        viewBox="0 0 959 1155"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute right-[-435px] top-[-175px] hidden lg:block z-[1] xl-1:-z-[0] pointer-events-none"
      >
        <g opacity="0.2">
          <path
            d="M326.967 364.904L81.7417 506.485L81.7417 223.323L326.967 364.904Z"
            stroke="white"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M817.418 364.904L572.193 506.485L572.193 223.323L817.418 364.904Z"
            stroke="white"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M572.192 789.819L326.966 931.4L326.966 648.238L572.192 789.819Z"
            stroke="white"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M326.967 364.904L572.192 506.485L572.192 223.323L326.967 364.904Z"
            stroke="white"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M572.192 789.819L817.417 931.4L817.417 648.238L572.192 789.819Z"
            stroke="white"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M572.193 223.325L326.968 81.7439V364.906L572.193 223.325Z"
            stroke="white"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M817.418 648.242L572.193 506.661V789.823L817.418 648.242Z"
            stroke="white"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M572.192 506.487L326.966 364.906V648.068L572.192 506.487Z"
            stroke="white"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M817.418 931.403L572.193 789.822V1072.98L817.418 931.403Z"
            stroke="white"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M326.97 364.903L326.97 81.7417L81.7444 223.323L326.97 364.903Z"
            stroke="white"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M572.194 789.82L572.194 506.659L326.969 648.24L572.194 789.82Z"
            stroke="white"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M326.97 648.066L326.97 364.904L81.7444 506.485L326.97 648.066Z"
            stroke="white"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M817.421 648.066L817.421 364.904L572.196 506.485L817.421 648.066Z"
            stroke="white"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M572.194 1072.98L572.194 789.82L326.969 931.401L572.194 1072.98Z"
            stroke="white"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
        </g>
      </svg>
      <svg
        width="302"
        height="219"
        viewBox="0 0 302 219"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="middle-arrow"
        className="absolute hidden xl-1:block bottom-[-115px] xl-1:bottom-[-100px] right-[85px] z-[2]"
      >
        <g style={{ mixBlendMode: 'overlay' }}>
          <path
            d="M271.313 67.9837L31.5909 206.387L6.11874 162.268L245.84 23.8646L271.313 67.9837Z"
            fill="url(#paint0_linear_2611_507)"
            fillOpacity="0.5"
          />
        </g>
        <path
          d="M228.33 34.1601L247.513 23.1358L273.092 37.8342L253.909 48.8585L228.33 34.1601Z"
          fill="#C93D94"
        />
        <path
          d="M253.906 78.2549L273.09 67.2306V37.8338L253.906 48.8581V78.2549Z"
          fill="url(#paint1_linear_2611_507)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2611_507"
            x1="98.2468"
            y1="144.714"
            x2="258.577"
            y2="45.9241"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              stopColor="#0C111D"
              stopOpacity="0"
            />
            <stop
              offset="1"
              stopColor="#090E1B"
              stopOpacity="0.5"
            />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2611_507"
            x1="272.916"
            y1="37.5325"
            x2="285.854"
            y2="59.9417"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DB7EB8" />
            <stop
              offset="1"
              stopColor="#C93D94"
            />
          </linearGradient>
        </defs>
      </svg>
      <svg
        width="155"
        height="112"
        viewBox="0 0 155 112"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="left-arrow"
        className="absolute hidden xl-1:block right-[230px] bottom-[-55px] xl-1:bottom-[-40px] z-[2]"
      >
        <g style={{ mixBlendMode: 'overlay' }}>
          <path
            d="M139.422 35.1L16.7591 105.919L3.72527 83.3441L126.388 12.5248L139.422 35.1Z"
            fill="url(#paint0_linear_2611_501)"
            fillOpacity="0.5"
          />
        </g>
        <path
          d="M117.428 17.7928L127.244 12.1518L140.332 19.6728L130.516 25.3138L117.428 17.7928Z"
          fill="#C93D94"
        />
        <path
          d="M130.516 40.3556L140.331 34.7146V19.6726L130.516 25.3136V40.3556Z"
          fill="url(#paint1_linear_2611_501)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2611_501"
            x1="50.8661"
            y1="74.3618"
            x2="132.905"
            y2="23.8123"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              stopColor="#0C111D"
              stopOpacity="0"
            />
            <stop
              offset="1"
              stopColor="#090E1B"
              stopOpacity="0.5"
            />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2611_501"
            x1="140.243"
            y1="19.5185"
            x2="146.863"
            y2="30.985"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DB7EB8" />
            <stop
              offset="1"
              stopColor="#C93D94"
            />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};
