import { Typography } from '@/components/01-new/atoms/00-ui/Typography';
import { Stars } from '@/components/01-new/atoms/03-decorations/Stars';
import { cn } from '@/helpers/tailwind';
import Image from 'next/image';

interface IReviewedCardProps {
  rating?: string;
  logo: 'clutch' | 'google';
  logoClassname?: string;
}

const ReviewedCard = ({ rating = '5.0', logo, logoClassname }: IReviewedCardProps) => {
  return (
    <div
      className="flex flex-col mobile:flex-row items-center
      justify-evenly mobile:justify-center gap-3 md-tablet:gap-6"
    >
      <div className="flex flex-col items-center justify-center gap-3">
        <Typography
          variant="caption"
          component="p"
          className="text-black-5 text-[14px] md-tablet:text-[20px] 
          uppercase tracking-wider whitespace-nowrap opacity-60"
        >
          Reviewed on
        </Typography>
        <Image
          src={`/icons/socials/${logo}-white.webp`}
          alt={`${logo} logo`}
          width="45"
          height="60"
          loading="lazy"
          className={cn('w-[25px] md-tablet:w-[45px] h-auto scale-[9] md-tablet:scale-[7] pointer-events-none', {
            [`${logoClassname}`]: logoClassname,
          })}
        />
      </div>
      <div className="flex flex-col items-center justify-center gap-1 mobile:gap-3">
        <Stars />
        <Typography
          component="p"
          variant="caption"
          className="text-black-5 text-[14px] md-tablet:text-[20px] 
          uppercase tracking-wider whitespace-nowrap opacity-60"
        >
          {`${rating}`} rating
        </Typography>
      </div>
    </div>
  );
};

export default ReviewedCard;
