'use client';

import { IFeedbacksSwiperSectionProps } from './index.types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/pagination';
import './index.styles.scss';
import { SwiperNavButton } from '@/components/Swipers/SwiperNavButton';
import { SwiperNavArrowColor, SwiperNavArrowOrientation } from '@/images/svg/SwiperNavArrow';
import FeedbackQuote from '@/components/01-new/molecules/00-ui/FeedbackSwiperQuote';
import ReviewedCard from '@/components/01-new/molecules/00-ui/ReviewedCard';
import dynamic from 'next/dynamic';
import { FeedbackSwiperComposition } from '@/components/01-new/molecules/03-decorations/FeedbackSwiperComposition';
const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

const FeedbacksSwiperSection = ({ titleSlot, list, videoUrl }: IFeedbacksSwiperSectionProps) => {
  return (
    <div className="flex flex-col gap-[40px] xl-1:gap-[10px]">
      {titleSlot}
      <div className="flex justify-center max-w-[1376px] w-full gap-[28px] self-center relative">
        {videoUrl && (
          <div className="absolute h-full w-full">
            <div
              className=" absolute inset-y-0 left-0 xl-1:left-[-2%] w-[10%] 
              bg-gradient-to-r from-black-11 to-transparent z-[1]"
            />
            <div
              className="absolute inset-y-0 right-0 xl-1:right-[39%] w-[10%] 
              bg-gradient-to-l from-black-11 to-transparent z-[1]"
            />
            <ReactPlayer
              url={videoUrl}
              playing
              loop
              muted
              playsinline
              width="100%"
              height="100%"
              className="feedbacks-background-video absolute top-[20px] xl-1:top-0 xl-1:left-[-22%] 
                w-full h-full object-cover z-0 opacity-30"
            />
          </div>
        )}
        <div className="w-full max-w-[1408px] h-full flex flex-col gap-[30px] xl-1:flex-row xl-1:gap-[60px]">
          <div className="flex flex-row xl-1:flex-col items-center">
            <SwiperNavButton
              orientation={SwiperNavArrowOrientation.LEFT}
              color={SwiperNavArrowColor.PINK}
              className="border-purple-13 active:bg-white relative z-[5] xl-1:top-0 border-0
                hover:disabled:border-purple-13 active:disabled:border-purple-13 left-feedback min-w-[44px]"
              arrowClassName="xl-1:rotate-90"
              name="Swipe left"
            />
            <Swiper
              modules={[Navigation, Autoplay]}
              autoplay={{
                delay: 10000,
              }}
              spaceBetween={30}
              slidesPerView={1}
              direction="horizontal"
              speed={1500}
              loop={true}
              grabCursor
              className="feedback-swiper xl-1:max-h-[600px] max-w-[978px] w-full xl-1:max-w-initial"
              navigation={{ nextEl: '.right-feedback', prevEl: '.left-feedback' }}
              breakpoints={{
                1280: {
                  direction: 'vertical',
                  slidesPerView: 2.5,
                  centeredSlides: true,
                  spaceBetween: 120,
                },
              }}
            >
              {list.map((item, index) => (
                <SwiperSlide
                  key={index}
                  className="flex"
                >
                  <FeedbackQuote
                    title={item.title}
                    description={item.description}
                    author={item.author}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <SwiperNavButton
              orientation={SwiperNavArrowOrientation.RIGHT}
              color={SwiperNavArrowColor.PINK}
              className="border-purple-13 active:bg-white relative z-[5] xl-1:bottom-0 border-0
                hover:disabled:border-purple-13 active:disabled:border-purple-13 right-feedback min-w-[44px]"
              arrowClassName="xl-1:rotate-90"
              name="Swipe right"
            />
          </div>

          <div
            className="flex flex-row xl-1:flex-col gap-3 md-tablet:gap-[40px] 
            justify-around xl-1:justify-center z-[5]"
          >
            <ReviewedCard
              logo="clutch"
              logoClassname="relative bottom-0 md-tablet:bottom-[6px]"
            />
            <ReviewedCard
              logo="google"
              rating="4.8"
              logoClassname="relative top-[6px] md-tablet:top-[2px]"
            />
          </div>
        </div>
        <FeedbackSwiperComposition />
      </div>
    </div>
  );
};

export default FeedbacksSwiperSection;
